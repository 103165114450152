import { getInitializedBrazeClient, initBrazeClient } from "./braze-client";
import { setBrazeUser, trackEvent, logCardDismissal } from "./utils";
import {
  useContentCards,
  useDismissContentCard,
  useLogCardImpressions,
  useWebInAppMessages,
  useTrackContentCardViewed,
} from "./hooks";

export default {
  initBrazeClient,
  getInitializedBrazeClient,
  setBrazeUser,
  trackEvent,
  useWebInAppMessages,
  useContentCards,
  useDismissContentCard,
  useLogCardImpressions,
  logCardDismissal,
  useTrackContentCardViewed,
};
