import { z } from "zod";
import { Permission } from "./user";

export enum BoardPermission {
  private = 0, // Only account users can access the board
  public = 1, // Anyone with the link can access the board
  publicReadOnly = 2, // Anyone with the link can access the board, but not edit it
}

export const PublicBoardPermissions = [BoardPermission.public, BoardPermission.publicReadOnly];
export function isPublicPermission(permission: BoardPermission) {
  return PublicBoardPermissions.includes(permission);
}

export const BoardPermissionMainTitles = {
  [BoardPermission.private]: "Only your team can access",
  [BoardPermission.public]: "Anyone with the link",
  [BoardPermission.publicReadOnly]: "Anyone with the link",
};

export const BoardPermissionAccessTitles = {
  [BoardPermission.private]: "",
  [BoardPermission.public]: "Can Edit",
  [BoardPermission.publicReadOnly]: "Can View",
};

export const BoardPermissionFullTitles = {
  [BoardPermission.private]: "Only your team can access",
  [BoardPermission.public]: "Anyone with the link can edit",
  [BoardPermission.publicReadOnly]: "Anyone with the link can view",
};

export enum BoardState {
  templateNotSetUp,
  default = 1,
  gettingStarted = 2,
}

export function fallback<T>(schema: z.ZodSchema<T>, value: T) {
  return z.any().transform((val) => {
    const safe = schema.safeParse(val);
    return safe.success ? safe.data : value;
  });
}

export type BoardDBSchema = {
  id: number;
  name: string;
  thumbnail: string | null;
  document_id: string;
  updated_at: string;
  permission: BoardPermission;
  account_id: number;
  is_owner: boolean;
  is_read_only: boolean | null;
  project_id: number | null;
  team_id: number | null;
  has_member_access: boolean;
  state: BoardState;
  created_from_template_id: string | null;
  is_downgraded: boolean;
  team_name: string | null;
  project_name: string | null;
  is_password_protected: boolean;
  password_hash: string | null;
  password_last_change: string | null;
  board_monday_info_id: number | null;
  deleted: boolean;
  created_by_user_id: number;
  created_at: string;
  created_reflect_room: boolean;
  user_canvas_permission: Permission;
};

export const BOARD_PUBLIC_FIELDS = [
  "id",
  "name",
  "document_id",
  "updated_at",
  "created_at",
  "thumbnail",
  "permission",
  "account_id",
  "created_reflect_room",
  "is_password_protected",
  "password_last_change",
  "board_monday_info_id",
  "state",
  "created_by_user_id",
] as Array<keyof BoardDBSchema>;

export type PublicBoardDBSchema = Pick<BoardDBSchema, typeof BOARD_PUBLIC_FIELDS[number]>;

export const BOARD_PRIVATE_FIELDS = [
  ...BOARD_PUBLIC_FIELDS,
  "created_from_template_id",
  "project_id",
  "team_id",
  "deleted",
  "is_read_only",
] as Array<keyof BoardDBSchema>;

export type PrivateBoardDBSchema = Pick<BoardDBSchema, typeof BOARD_PRIVATE_FIELDS[number]>;

export const MINIMAL_BOARD_FIELDS_FROM_DB = [
  "id",
  "name",
  "document_id",
  "permission",
  "project_id",
  "team_id",
  "is_owner",
  "thumbnail",
  "updated_at",
  "has_member_access",
  "is_read_only",
  "user_canvas_permission",
] as Array<keyof BoardDBSchema>;

export type MinimalBoardDBSchema = Pick<BoardDBSchema, typeof MINIMAL_BOARD_FIELDS_FROM_DB[number]>;

export const boardSchema = z.object({
  id: z.number(),
  name: z.string(),
  thumbnail: z.string().nullable(),
  documentId: z.string(),
  updatedAt: z.date(),
  permission: fallback(z.nativeEnum(BoardPermission), BoardPermission.private),
  accountId: z.string(),
  isOwner: z.boolean().default(false),
  isReadOnly: z.boolean().default(false).nullable(),
  projectId: z.number().nullable().default(null),
  teamId: z.number().nullable().default(null),
  hasMemberAccess: z.boolean().default(false),
  state: fallback(z.nativeEnum(BoardState), BoardState.templateNotSetUp),
  createdFromTemplateId: z.string().nullish(),
  isDowngraded: z.boolean().default(false).optional(),
  teamName: z.string().nullish(),
  projectName: z.string().nullish(),
  isPasswordProtected: z.boolean().default(false),
  boardMondayInfoId: z.number().nullable(),
  createdByUserId: z.number().nullable().default(null),
  userCanvasPermission: fallback(z.nativeEnum(Permission), Permission.viewer),
});

export type Board = z.infer<typeof boardSchema>;

export function boardFromDBModel(obj: any): Board {
  return boardSchema.parse({
    id: obj.id,
    name: obj.name,
    thumbnail: obj.thumbnail,
    documentId: obj.document_id,
    updatedAt: new Date(obj.updated_at),
    permission: obj.permission,
    accountId: obj.account_id.toString(),
    isOwner: obj.is_owner,
    isReadOnly: obj.is_read_only,
    projectId: obj.project_id,
    teamId: obj.team_id,
    hasMemberAccess: obj.has_member_access,
    state: obj.state,
    createdFromTemplateId: obj.created_from_template_id,
    isDowngraded: obj.is_downgraded,
    teamName: obj.team_name,
    projectName: obj.project_name,
    isPasswordProtected: obj.is_password_protected,
    boardMondayInfoId: obj.board_monday_info_id,
    createdByUserId: obj.created_by_user_id,
  });
}

export const boardsResponseSchema = z.object({
  boards: z.array(boardSchema),
});

export type BoardsResponse = z.infer<typeof boardsResponseSchema>;

export const minimalBoardSchema = boardSchema.pick({
  id: true,
  name: true,
  documentId: true,
  permission: true,
  projectId: true,
  teamId: true,
  isOwner: true,
  thumbnail: true,
  updatedAt: true,
  hasMemberAccess: true,
  isReadOnly: true,
  userCanvasPermission: true,
});

export type MinimalBoard = z.infer<typeof minimalBoardSchema>;

export function minimalBoardFromDBModel(object: MinimalBoardDBSchema): MinimalBoard {
  return minimalBoardSchema.parse({
    id: object.id,
    name: object.name,
    documentId: object.document_id,
    permission: object.permission,
    isOwner: object.is_owner,
    projectId: object.project_id,
    teamId: object.team_id,
    thumbnail: object.thumbnail,
    updatedAt: new Date(object.updated_at),
    hasMemberAccess: object.has_member_access,
    isReadOnly: object.is_read_only,
    userCanvasPermission: object.user_canvas_permission,
  });
}

export const boardMembersSchema = z.object({
  documentId: z.string(),
  members: z.array(
    z.object({
      id: z.number(),
      permission: fallback(z.nativeEnum(Permission), Permission.editor),
      deleted: z.boolean().default(false),
    })
  ),
});

export type BoardMembers = z.infer<typeof boardMembersSchema>;

export function boardMembersFromDBModel(object: unknown): BoardMembers {
  const data = object as {
    documentId: string;
    members: Array<{ user_id: number; permission: Permission; deleted: boolean }>;
  };
  return boardMembersSchema.parse({
    documentId: data.documentId,
    members: data.members.map((member) => ({
      id: member.user_id,
      permission: member.permission,
      deleted: member.deleted,
    })),
  });
}

const boardMemberCacheSchema = z.record(
  z.string(), // documentId
  z.object({
    boardMembers: boardMembersSchema,
    lastUpdated: z.date(),
  })
);

export type BoardMemberCache = z.infer<typeof boardMemberCacheSchema>;

export const boardWithMembersSchema = boardSchema.extend({
  members: z.array(
    z.object({
      id: z.number(),
      permission: fallback(z.nativeEnum(Permission), Permission.editor),
    })
  ),
});

export type BoardWithMembers = z.infer<typeof boardWithMembersSchema>;

const boardCacheSchema = z.record(
  z.string(), // documentId key
  z.object({
    board: minimalBoardSchema,
    lastUpdated: z.date(),
  })
);

export type BoardCache = z.infer<typeof boardCacheSchema>;
