import { z } from "zod";
import { canvasElementBaseSchema, pointSchema } from "./canvas-element";
import { textEnabledSchema } from "./text-enabled-schema";

export const textBlockSchemaInternal = z.object({
  type: z.literal("textBlock"),
  id: z.string(),
  width: z.number(),
  height: z.number(),
  rotate: z.number().optional().default(0),
  textPosition: pointSchema,
});
export const textBlockSchema = canvasElementBaseSchema.merge(textBlockSchemaInternal).merge(textEnabledSchema);

export type TextBlock = z.infer<typeof textBlockSchema>;
