import type { ClassicCard } from "@braze/web-sdk";
import { useEffect } from "react";
import { useSetAtom } from "jotai";
import { contentCardsAtom } from "./atoms";
import { getInitializedBrazeClient, getInitializedBrazeClientWithRetry } from "./braze-client";
import { BRAZE_CONTENT_CARDS_REFRESH_INTERVAL } from "./consts";
import { logCardDismissal } from "./utils";
import tracking from "frontend/tracking";

export function useWebInAppMessages() {
  useEffect(() => {
    const initializeClient = async () => {
      const brazeClient = await getInitializedBrazeClientWithRetry();

      if (brazeClient) {
        brazeClient.subscribeToInAppMessage((message) => {
          brazeClient.showInAppMessage(message);
        });
      }
    };

    initializeClient();
  }, []);
}

export function useContentCards() {
  const setContentCards = useSetAtom(contentCardsAtom);

  useEffect(() => {
    let recurringRefetch: NodeJS.Timeout;

    const initializeClient = async () => {
      const brazeClient = await getInitializedBrazeClientWithRetry();

      if (brazeClient) {
        brazeClient.subscribeToContentCardsUpdates((cards) => {
          setContentCards(cards.cards as ClassicCard[]);
        });

        brazeClient.requestContentCardsRefresh();

        recurringRefetch = setInterval(() => {
          brazeClient.requestContentCardsRefresh();
        }, BRAZE_CONTENT_CARDS_REFRESH_INTERVAL);
      }
    };

    initializeClient();

    return () => {
      if (recurringRefetch) {
        clearInterval(recurringRefetch);
      }
    };
  }, [setContentCards]);
}

export function useDismissContentCard(contentCard: ClassicCard) {
  useEffect(() => {
    const dismissCard = () => {
      logCardDismissal(contentCard);
    };

    window.addEventListener("beforeunload", dismissCard);

    return () => {
      window.removeEventListener("beforeunload", dismissCard);
      dismissCard();
    };
  }, [contentCard]);
}

export function useLogCardImpressions(contentCard: ClassicCard) {
  useEffect(() => {
    const brazeClient = getInitializedBrazeClient();
    if (contentCard) {
      brazeClient?.logCardImpressions([contentCard]);
    }
  }, []);
}

export function useTrackContentCardViewed(contentCard: ClassicCard) {
  useEffect(() => {
    if (contentCard?.extras?.eventName) {
      tracking.trackAnalyticsEvent(contentCard.extras.eventName);
    }
  }, []);
}
