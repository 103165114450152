import dynamic from "next/dynamic";
import { CSSProperties } from "react";
import { getPathPrefix } from "../utils/getPathPrefix";

const Player = dynamic(() => import("@lottiefiles/react-lottie-player").then((mod) => mod.Player), {
  ssr: false,
});

export default function AppLoader({
  customStyle,
  lottieSrc = getPathPrefix("/images/loading-v3.json"),
}: {
  customStyle?: CSSProperties;
  lottieSrc?: string;
}) {
  return (
    <Player
      autoplay
      loop
      src={lottieSrc}
      style={{
        position: "fixed",
        left: "50%",
        top: "50%",
        width: "300px",
        height: "300px",
        transform: "translate(-50%, -50%)",
        ...customStyle,
      }}
    />
  );
}
