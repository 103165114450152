type Braze = typeof import("@braze/web-sdk");

let brazeInstance: Braze | null = null;

export async function initBrazeClient() {
  if (typeof window !== "undefined" && typeof navigator !== "undefined") {
    try {
      const brazeModule = await import("@braze/web-sdk");
      brazeInstance = brazeModule.default || brazeModule;

      if (!process.env.BRAZE_API_KEY) return;

      brazeInstance.initialize(process.env.BRAZE_API_KEY, {
        baseUrl: "sdk.iad-06.braze.com",
      });
    } catch (error) {
      console.error("Failed to initialize Braze SDK:", error);
    }
  }
}

export function getInitializedBrazeClient() {
  return brazeInstance?.isInitialized() ? brazeInstance : null;
}

/**
 * Gets an initialized Braze client with retry capability
 * @param maxRetries Maximum number of retry attempts (default: 3)
 * @param retryInterval Time in ms between retries (default: 2000)
 * @returns Promise that resolves to the Braze client or null if all retries fail
 */
export async function getInitializedBrazeClientWithRetry(maxRetries = 3, retryInterval = 1000): Promise<Braze | null> {
  let retryCount = 0;

  const attemptGetClient = async (): Promise<Braze | null> => {
    const client = getInitializedBrazeClient();

    if (client) {
      return client;
    }

    if (retryCount < maxRetries) {
      retryCount++;
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
      return attemptGetClient();
    }

    return null;
  };

  return attemptGetClient();
}
