/* This file uses google analytics functions that are not defined or imported.
   Everything is fine since we load google analytics functions before calling the events. */
/* eslint-disable no-undef */

import consts from "../shared/consts";
import mixpanel from "mixpanel-browser";
import { readCookieValue } from "./utils/cookie-utils";
import posthog from "posthog-js";
import brazeSdk from "./braze-sdk";
import { isInIframe, iframeHasSessionToken } from "./utils/url-utils";

function initMixPanelClient() {
  const source = readCookieValue(consts.COOKIE_NAMES.SOURCE);
  mixpanel.init(process.env.MIXPANEL_TOKEN, { debug: false, api_host: "https://mixpanel.workcanvas.com" });
  mixpanel.register_once({
    page_url: window.location.href,
    referrer: source,
  });
}

function registerProperties(properties) {
  mixpanel.register(properties);
  posthog.register(properties);
}

function trackMixPanel(event, props, retriesNumber = 0) {
  const MAX_RETRIES_NUMBER = 2;
  if (retriesNumber < MAX_RETRIES_NUMBER) {
    try {
      mixpanel.track(event, props);
    } catch {
      //when a user enters directly to a canvas, we need to initialized the client first since it is being initialized in boards page
      console.log("error sending mix panel event, retrying");
      initMixPanelClient();
      trackMixPanel(event, props, ++retriesNumber);
    }
  } else {
    console.log("max retry limit reached, skipping");
  }
}

function trackPostHog(event, props, retriesNumber = 0) {
  const MAX_RETRIES_NUMBER = 2;
  if (retriesNumber < MAX_RETRIES_NUMBER) {
    try {
      posthog.capture(event, props);
    } catch {
      console.log("error sending posthog event, retrying");
      trackPostHog(event, props, ++retriesNumber);
    }
  } else {
    console.log("max retry limit reached for posthog, skipping");
  }
}

// Create a variable to store the persistent parameters
let persistentParams = {};

/**
 * Sets persistent parameters that will be automatically included in all tracking events
 * @param {Object} params - JSON object containing parameters to persist across all tracking events
 * @returns {Object} The current set of persistent parameters
 */
function setPersistentParams(params = {}) {
  persistentParams = { ...persistentParams, ...params };
  return persistentParams;
}

/**
 * Clears all or specific persistent parameters
 * @param {Array<string>} [paramKeys] - Optional array of parameter keys to clear
 * @returns {Object} The updated persistent parameters object
 */
function clearPersistentParams(paramKeys) {
  if (Array.isArray(paramKeys)) {
    paramKeys.forEach((key) => {
      delete persistentParams[key];
    });
  } else {
    persistentParams = {};
  }
  return persistentParams;
}

/**
 * Legacy function to track events in mixpanel and posthog with very specific parameters
 * @deprecated Use trackAnalyticsEvent instead
 * @see trackAnalyticsEvent
 *   For migrating to trackAnalyticsEvent, use the following rules:
 * - action param -> eventName param
 * - stringValue param -> params.stringValue param
 * - property param -> params.property param
 * - floatValue param -> params.floatValue param
 * - context param -> params.context param
 * - category param -> eventName param
 * - trackEvent(category, action, stringValue, property, floatValue, context) -> trackAnalyticsEvent(eventName, params)
 * @param {*} category
 * @param {*} action
 * @param {*} [stringValue]
 * @param {*} [property]
 * @param {*} [floatValue]
 * @param {*} [context]
 */
function trackEvent(category, action, stringValue, property, floatValue, context) {
  const eventParams = {
    stringValue,
    property,
    floatValue,
    context,
    category,
  };

  trackAnalyticsEvent(action, eventParams);
}

function trackAnalyticsEvent(eventName, params) {
  // keep the console on for localhost
  if (typeof window !== "undefined" && window.location.hostname === "localhost") {
    console.log("trackAnalyticsEvent", { eventName, params });
  }
  params = params || {};
  params.inIframe = isInIframe();
  params.inMondayIframe = iframeHasSessionToken();

  // Merge with persistent params
  const eventParams = { ...params, ...persistentParams };

  trackMixPanel(eventName, eventParams);
  trackPostHog(eventName, eventParams);
  brazeSdk.trackEvent(eventName, params);
}

function reportGoogleAnalyticsConversion(conversionType, conversionData) {
  switch (conversionType) {
    case consts.GA_CONVERSION_TYPES.HARD_SIGNUP: {
      gtag_report_hard_signup_conversion();
      break;
    }
    case consts.GA_CONVERSION_TYPES.UPGRADE_CTA: {
      gtag_report_upgrade_cta_conversion();
      break;
    }
    case consts.GA_CONVERSION_TYPES.NEW_ITEM_PLACED: {
      gtag_report_new_item_placed_conversion();
      break;
    }
    case consts.GA_CONVERSION_TYPES.INVITE_LINK_COPIED: {
      gtag_report_invite_link_copied_conversion();
      break;
    }
    case consts.GA_CONVERSION_TYPES.CANVAS_LIMIT_REACHED: {
      gtag_report_canvas_limit_reched_conversion();
      break;
    }
    case consts.GA_CONVERSION_TYPES.RETURNING_USER: {
      gtag_report_retention_conversion(conversionData);
      break;
    }
    case consts.GA_CONVERSION_TYPES.PROMOTION_CLICKED: {
      gtag_report_promo_conversion(conversionData);
      break;
    }
    case consts.GA_CONVERSION_TYPES.PAID_PLAN_SELECTED: {
      gtag_report_paid_plan_selected_conversion(conversionData);
      gtag_report_paid_plan_selected_conversion_fixed(conversionData);
      break;
    }
    case consts.GA_CONVERSION_TYPES.PAYMENT_COMPLETED: {
      gtag_report_payment_completed(conversionData);
      gtag_report_payment_completed_fixed(conversionData);
      break;
    }
    case consts.GA_CONVERSION_TYPES.TEAM_SIGNUP: {
      gtag_report_team_signup_conversion();
      break;
    }
    default: {
      break;
    }
  }
}

export default {
  trackEvent,
  trackAnalyticsEvent,
  reportGoogleAnalyticsConversion,
  initMixPanelClient,
  registerProperties,
  setPersistentParams,
  clearPersistentParams,
};
