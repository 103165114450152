import React, { createContext, useCallback, useContext, useState, FC } from "react";
import Modal from "./modal";

export interface ModalProps<T> {
  onComplete: (result: T) => void;
  onClose: () => void;
}

export type ModalComponent<P = object, TProps = ModalProps<P>, AnyProps = object> = FC<TProps & AnyProps> & {
  componentName: string;
};

// Type for the modal registry
type ModalRegistry = {
  [key: string]: ModalComponent<any, any>;
};

// Type for the modal state
type ModalState<T> = {
  isOpen: boolean;
  component: keyof ModalRegistry | null;
  props?: any;
  onComplete?: (result: T) => void;
};

// Context for modal state and controls
const ModalContext = createContext<{
  showModal: <P, T, AnyProps>(
    component: ModalComponent<P, T, AnyProps>,
    onComplete?: (result: P) => void,
    props?: AnyProps
  ) => void;
  hideModal: () => void;
} | null>(null);

// Modal registry to store all available modal components
const modalRegistry: ModalRegistry = {};

// Provider component
export function DynamicModalProvider({ children }: { children: React.ReactNode }) {
  const [modalState, setModalState] = useState<ModalState<any>>({
    isOpen: false,
    component: null,
  });

  const showModal = useCallback(
    <P, T, AnyProps>(component: ModalComponent<P, T, AnyProps>, onComplete?: (result: P) => void, props?: AnyProps) => {
      setModalState({
        isOpen: true,
        component: component.componentName,
        props,
        onComplete,
      });
    },
    []
  );

  const hideModal = useCallback(() => {
    setModalState({
      isOpen: false,
      component: null,
    });
  }, []);

  const completeModal = useCallback(
    <T,>(result: T) => {
      modalState.onComplete?.(result);
      hideModal();
    },
    [modalState.onComplete, hideModal]
  );

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {modalState.isOpen && modalState.component && (
        <Modal>
          {React.createElement(modalRegistry[modalState.component], {
            ...modalState.props,
            onComplete: completeModal,
            onClose: hideModal,
          })}
        </Modal>
      )}
    </ModalContext.Provider>
  );
}

// Hook to use the modal context
export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a DynamicModalProvider");
  }
  return context;
}

// Class to handle modal registration and showing
export const DynamicModal = {
  register<P, TProps, AnyProps>(component: ModalComponent<P, TProps, AnyProps>) {
    modalRegistry[component.componentName] = component;
  },
};
