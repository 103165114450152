import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { User } from "shared/datamodel/schemas";
import { posthogInit } from "utils/analytic-utils";

type PosthogInitProps = {
  user: User;
  version: string;
};

export const PosthogInit = ({ user, version }: PosthogInitProps) => {
  const posthog = usePostHog();

  useEffect(() => {
    if (user && posthog) {
      console.log("init posthog");
      posthogInit(posthog, user, version);
    }
  }, [posthog, user, version]);

  return null;
};
