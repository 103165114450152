import { atom } from "jotai";
import type { ClassicCard } from "@braze/web-sdk";

export const contentCardsAtom = atom<ClassicCard[]>([]);

export const headerContentCardsAtom = atom((get) => {
  return get(contentCardsAtom).filter((card) => card.extras?.type === "header");
});

export const canvasAnnouncementContentCardAtom = atom((get) => {
  return get(contentCardsAtom).find((card) => card.extras?.type === "canvas-announcement");
});
