import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";
import { textEnabledSchema } from "./text-enabled-schema";

export const shapeSchemaInternal = z.object({
  type: z.string(),
  subtype: z.string().optional(),
  width: z.number().default(200),
  height: z.number().default(200),
  radius: z.number().default(100),
  rotate: z.number().default(0),
  fill: z.string(),
  scaleX: z.number().default(1),
  scaleY: z.number().default(1),
  originalScaleX: z.number().optional(),
  originalScaleY: z.number().optional(),

  border: z.string().optional(),
  strokeWidth: z.string().or(z.number()),
  dash: z.number().default(0),

  textArea: z.array(z.number()).length(4).optional(),
});

export const defaultShapeDimensions = { width: 200, height: 200, radius: 100 };
export const defaultShapeStrokeWidth = 4;
export const defaultShapeProperties = { width: 200, height: 200, radius: 100, dash: 0, strokeWidth: 4 };

export const shapeSchema = canvasElementBaseSchema.merge(shapeSchemaInternal).merge(textEnabledSchema);
export type Shape = z.infer<typeof shapeSchema>;
export type ShapeRequiredOnly = z.input<typeof shapeSchema>;
