import { ClassicCard } from "@braze/web-sdk";
import { getInitializedBrazeClient } from "./braze-client";

export function setBrazeUser(userId: string, flattenedUserProperties: Record<string, never>) {
  const brazeClient = getInitializedBrazeClient();
  if (!brazeClient) return;

  brazeClient.changeUser(userId);

  for (const [key, value] of Object.entries(flattenedUserProperties)) {
    brazeClient.getUser()?.setCustomUserAttribute(key, value);
  }
  brazeClient.logCustomEvent("user_logged_in");
  brazeClient.openSession();
}

export function trackEvent(event: string, props: Record<string, unknown>) {
  const brazeClient = getInitializedBrazeClient();
  if (!brazeClient) return;

  brazeClient.logCustomEvent(event, props);
}

export function logCardDismissal(contentCard?: ClassicCard) {
  const brazeClient = getInitializedBrazeClient();

  if (contentCard) {
    brazeClient?.logCardDismissal(contentCard);
  }
}
