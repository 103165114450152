import { Project } from "shared/datamodel/schemas/project";
import { z } from "zod";
import { Permission } from "./user";

export enum State {
  deleted,
  active,
}

export function fallback<T>(schema: z.ZodSchema<T>, value: T) {
  return z.any().transform((val) => {
    const safe = schema.safeParse(val);
    return safe.success ? safe.data : value;
  });
}

export const userTeamPermissionSchema = z.object({
  id: z.string(),
  state: fallback(z.nativeEnum(State), State.active),
  permission: fallback(z.nativeEnum(Permission), Permission.editor),
});

export type UserTeamPermission = z.infer<typeof userTeamPermissionSchema>;

export function userTeamPermissionFromDBModel(obj: any): UserTeamPermission {
  return userTeamPermissionSchema.parse({
    id: obj.id.toString(),
    state: obj.state,
    permission: obj.permission,
  });
}

export const teamSchema = z.object({
  id: z.number(),
  name: z.string(),
  documentId: z.string(),
  updatedAt: z.date(),
  createdAt: z.date(),
  accountId: z.string(),
  isOwner: z.boolean().default(false),
  permission: z.nativeEnum(Permission).default(Permission.editor),
  accountTeam: z.boolean().nullable().default(false),
  state: fallback(z.nativeEnum(State), State.active),
  userTeamPermissions: z.array(userTeamPermissionSchema),
  projects: z.array(z.any()).nullable(), //TODO: fix any
  createdByUserId: z.number().optional(),
});

export type Team = z.infer<typeof teamSchema>;

export function teamFromDBModel(obj: any): Team {
  return teamSchema.parse({
    id: obj.id,
    name: obj.name,
    documentId: obj.document_id,
    updatedAt: new Date(obj.updated_at),
    createdAt: new Date(obj.created_at),
    accountId: obj.account_id.toString(),
    isOwner: obj.is_owner,
    permission: obj.permission,
    accountTeam: obj.account_team,
    state: obj.state,
    userTeamPermissions: obj.user_team_permissions?.map(userTeamPermissionFromDBModel) ?? [],
    projects: obj.projects ?? null,
    createdByUserId: obj.created_by_user_id,
  });
}

export const teamDBSchema = z.object({
  id: z.number(),
  name: z.string(),
  document_id: z.string(),
  updated_At: z.date(),
  created_At: z.date(),
  account_id: z.string(),
  is_owner: z.boolean().default(false),
  permission: z.nativeEnum(Permission).default(Permission.editor),
  account_team: z.boolean().nullable().default(false),
  state: fallback(z.nativeEnum(State), State.active),
  created_by_user_id: z.number().optional(),
});

export type TeamDBSchema = z.infer<typeof teamDBSchema>;

export const teamsResponseSchema = z.object({
  teams: z.array(teamSchema),
});

export type TeamsResponse = z.infer<typeof teamsResponseSchema>;

export const pendingUserPermissionSchema = z.object({
  id: z.string(),
  permission: z.nativeEnum(Permission),
});

export function pendingUserPermissionFromDBModal(obj: any): PendingUserPermission | null {
  if (obj) {
    try {
      const pendingUserPermission = pendingUserPermissionSchema.parse({
        id: obj.user_id.toString(),
        permission: obj.permission,
      });
      return pendingUserPermission;
    } catch (error) {
      console.error("Error parsing pending user permission from DB model", error);
    }
  }
  return null;
}

export type PendingUserPermission = z.infer<typeof pendingUserPermissionSchema>;

export type TeamWithProjects = Pick<Team, "id" | "name" | "permission" | "isOwner"> & {
  projects: Array<Pick<Project, "id" | "name" | "permission" | "isOwner">>;
};

const teamCacheSchema = z.record(
  z.string(), // teamId key
  z.object({
    team: teamSchema,
    lastUpdated: z.date(),
  })
);

export type TeamCache = z.infer<typeof teamCacheSchema>;
