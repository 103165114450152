import { z } from "zod";

export const dataLayerFieldSchema = z.object({
  type: z.string(),
  title: z.string(),
  isSystem: z.boolean().default(false),
  settings_str: z.string().optional(),
});

const dataLayerIntegrationValue = z.object({
  integrationId: z.string(),
  itemId: z.string(),
  columnId: z.string(),
  columnType: z.string(),
});

const dataLayerValueSchema = z.union([z.string(), dataLayerIntegrationValue]);

export enum ElementRuleComparator {
  eq = "eq",
  neq = "neq",
  gt = "gt",
  lt = "lt",
  gte = "gte",
  lte = "lte",
  contains = "contains",
  not_contains = "not_contains",
}

const comparatorSchema = z.nativeEnum(ElementRuleComparator);

export enum ElementRuleValueType {
  text = "text",
  color = "color",
  icon = "icon",
}

export const ruleSchema = z.object({
  id: z.string(),
  fieldId: z.string(),
  comparator: comparatorSchema,
  compareValue: z.string(),
  action: z.object({
    property: z.string(),
    value: z.string(),
  }),
  active: z.boolean(),
});

export const elementDataLayerSchema = z.object({
  fieldsOrder: z.array(z.string()).default([]),
  fields: z.record(dataLayerFieldSchema).default({}),
  values: z.record(dataLayerValueSchema).default({}),
  rules: z.array(ruleSchema).default([]),
  icons: z.record(z.string()).optional(), // [fieldId: string] => icon
  integrations: z
    .record(
      z.record(
        z.object({
          columnIds: z.union([z.array(z.string()), z.literal("all")]),
        })
      )
    )
    .optional(), // [integrationId: string] => { [itemId: string] => { columnIds: string[] | "all" } }
});

export type ElementDataLayerField = z.infer<typeof dataLayerFieldSchema>;
export type ElementDataLayerIntegrationValue = z.infer<typeof dataLayerIntegrationValue>;
export type ElementDataLayerValue = z.infer<typeof dataLayerValueSchema>;
export type ElementRule = z.infer<typeof ruleSchema>;
export type ElementDataLayer = z.infer<typeof elementDataLayerSchema>;
