import { usePortal } from "./usePortal";
import { PortalID } from "./portal-types";
import * as ReactDOM from "react-dom";
import { CSSProperties, useLayoutEffect, useState } from "react";
import ModalContent from "./modal-content";

interface ModalProps {
  children: any;
  dimBackground?: boolean;
  blurBackground?: boolean;
  withPortal?: boolean;
  portalID?: string;
  zIndex?: number;
  theme?: "dark" | "light";
  customStyle?: CSSProperties;
  onClickBackground?: () => void;
  onDismissOnEscape?: () => void;
  type?: string;
}

export default function Modal({
  children,
  dimBackground = false,
  blurBackground = false,
  withPortal = true,
  portalID = PortalID.Modal,
  zIndex,
  theme,
  customStyle,
  onClickBackground,
  onDismissOnEscape,
  type,
}: ModalProps) {
  theme = theme || "dark";

  function renderContent() {
    return (
      <ModalContent
        blurBackground={blurBackground}
        dimBackground={dimBackground}
        type={type}
        zIndex={zIndex}
        customStyle={customStyle}
        onClickBackground={onClickBackground}
        onDismissOnEscape={onDismissOnEscape}
        theme={theme}
      >
        {children}
      </ModalContent>
    );
  }

  // This is a workaround to a problem with react portals:
  // https://github.com/reactjs/reactjs.org/issues/272
  const [isMounted, setIsMounted] = useState(false);
  useLayoutEffect(() => {
    setIsMounted(true);
  }, []);

  const target = usePortal(portalID);

  if (!withPortal) return renderContent();
  return ReactDOM.createPortal(isMounted ? renderContent() : null, target);
}
