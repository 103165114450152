import { z } from "zod";
import { canvasElementBaseSchema } from "./canvas-element";
import { textEnabledSchema } from "./text-enabled-schema";

export const stickyNoteSchemaInternal = z.object({
  type: z.literal("stickyNote"),
  width: z.number(),
  height: z.number(),
  fill: z.string(),
  scaleX: z.number(),
  scaleY: z.number(),
  isWide: z.boolean().default(false),
});
export const stickyNoteSchema = canvasElementBaseSchema.merge(stickyNoteSchemaInternal).merge(textEnabledSchema);

export type StickyNote = z.infer<typeof stickyNoteSchema>;
