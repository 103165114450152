import { CSSProperties } from "react";
import { useKey } from "react-use";

interface ModalContentProps {
  children: React.ReactNode;
  blurBackground: boolean;
  dimBackground: boolean;
  type?: string;
  zIndex?: number;
  customStyle?: CSSProperties;
  onClickBackground?: () => void;
  onDismissOnEscape?: () => void;
  theme?: "dark" | "light";
}

export default function ModalContent({
  children,
  blurBackground,
  dimBackground,
  type,
  zIndex,
  customStyle,
  onClickBackground,
  onDismissOnEscape,
  theme,
}: ModalContentProps) {
  useKey("Escape", onDismissOnEscape);

  function getBackgroundStyle() {
    switch (theme) {
      case "dark": {
        return "rgba(17,51,87,0.65)";
      }
      case "light": {
        return "rgba(255,255,255,0.5)";
      }
      default: {
        return "rgba(0,0,0,0.2)";
      }
    }
  }

  return (
    <div
      className="bg"
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "grid",
        placeItems: "center",
        backdropFilter: blurBackground ? "blur(6px)" : undefined,
        backgroundColor: dimBackground ? getBackgroundStyle() : "transparent",
        pointerEvents: type === "walkthrough-tip" ? "none" : "auto",
        zIndex,
        ...customStyle,
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: -1,
          pointerEvents: type === "walkthrough-tip" ? "none" : "auto",
        }}
        onClick={onClickBackground}
      />
      {children}
    </div>
  );
}
